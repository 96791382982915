import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Input, Button, List, Avatar, Spin, Skeleton, Modal, Form, Select, DatePicker, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ChatWindow = ({ userData, setUserData, setAnswers }) => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isConversationEnded, setIsConversationEnded] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contactData, setContactData] = useState({});

    const inputRef = useRef(null);
    const messageListRef = useRef(null);


    const initializeConversation = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/start-conversation`);
            if (response.data && response.data.userId) {
                setUserData(prevData => ({ ...prevData, userId: response.data.userId }));
                setIsConversationEnded(false);
                setMessages([{ sender: 'bot', message: 'Tervetuloa Outomaattiin! Voit kysyä minulta mitä tahansa Outoplanin toiminnasta. Kun olet valmis, voit jättää yhteydenottopyynnön klikkaamalla punaista nappia "Jätä yhteydenottopyyntö".' }]);
            }
        } catch (error) {
            console.error('Error initializing conversation:', error);
        }
    };

    useEffect(() => {
        initializeConversation();
    }, []);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTo({
                top: messageListRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [messages]);

    useEffect(() => {
        // Tarkista onko userId saatavilla ennen intervallin asettamista
        if (!userData.userId) return;

        const intervalId = setInterval(async () => {
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/heartbeat`, { userId: userData.userId });
                console.log("Heartbeat lähetetty käyttäjälle:", userData.userId);
            } catch (error) {
                console.error('Error sending heartbeat:', error);
            }
        }, 60000); // Lähetä sydämenlyönti minuutin välein (60000 ms)

        // Tyhjennä intervalli, kun komponentti unmountataan
        return () => clearInterval(intervalId);
    }, [userData.userId]); // Lisää userId riippuvuutena
    


    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleUserResponse = async (event) => {
        event.preventDefault();
        if (!input.trim()) return;

        setIsLoading(true);
        const skeletonMessage = { sender: 'bot', message: 'Ladataan...', isSkeleton: true };

        try {
            const { userId } = userData;

            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'user', message: input }
            ]);

            setMessages(prevMessages => [
                ...prevMessages,
                skeletonMessage
            ]);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/user-response`, {
                userId,
                message: input
            });

            setMessages(prevMessages => [
                ...prevMessages.slice(0, -1),
                { sender: 'bot', message: response.data.response }
            ]);
        } catch (error) {
            console.error('Failed to process user response:', error);
            alert('Error processing response. Please try again.');
        } finally {
            setInput('');
            setIsLoading(false);
            inputRef.current.focus();
        }
    };

    const openContactModal = () => {
        setIsModalVisible(true);
    };

    const handleModalOk = async () => {
        setIsLoading(true);
        const { userId } = userData;

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/end-conversation`, {
                userId,
                contactData,
                conversationHistory: messages
            });

            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'bot', message: 'Kiitos keskustelusta! Olemme vastaanottaneet yhteydenottopyyntösi.' }
            ]);
            setIsConversationEnded(true);
        } catch (error) {
            console.error('Failed to end conversation:', error);
            alert('Error ending conversation. Please try again.');
        } finally {
            setIsLoading(false);
            setIsModalVisible(false);
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleContactDataChange = async (value, name) => {
        setContactData(prevData => ({ ...prevData, [name]: value }));
        try {
            const { userId } = userData;
            await axios.post(`${process.env.REACT_APP_API_URL}/api/update-contact-data`, {
                userId,
                contactData: { [name]: value }
            });
        } catch (error) {
            console.error('Failed to update contact data:', error);
        }
    };

    

    const renderMessageItem = (item) => {
        if (item.isSkeleton) {
            return (
                <List.Item>
                    <Skeleton active paragraph={{ rows: 1 }} avatar={{ shape: 'circle' }} />
                </List.Item>
            );
        }

        return (
            <List.Item>
                <List.Item.Meta
                    avatar={<Avatar style={{ backgroundColor: item.sender === 'user' ? '#f56a00' : '#87d068' }} icon={item.sender === 'user' ? 'user' : 'robot'} />}
                    title={item.sender === 'user' ? 'Vastauksesi' : 'OutoBot'}
                    description={<ReactMarkdown>{item.message}</ReactMarkdown>}

                />
            </List.Item>
        );
    };

    return (
        <div id="wrap">
            <div className="header">
                <div className="inner-container">
                    <h2>Outomaatti</h2>
                </div>
            </div>

            <div className="chat-window">
                {!isConversationEnded ? (
                    <div className="message-list" ref={messageListRef}>
                        <List dataSource={messages} renderItem={renderMessageItem} />
                    </div>
                ) : (
                    <div className="end-message">
                        <h3>Kiitos yhteydenotostasi!</h3>
                        <p>Olemme vastaanottaneet tietosi ja otamme sinuun yhteyttä mahdollisimman pian.</p>
                        <p>Jos sinulla on kiireellistä asiaa, olethan yhteydessä asiakaspalveluumme.</p>
                        <Button type="primary" onClick={initializeConversation}>
                            Aloita uusi keskustelu
                        </Button>
                    </div>
                )}
                <div className="message-field">
                    <Spin spinning={isLoading} indicator={antIcon}>
                        {!isConversationEnded && (
                            <form onSubmit={handleUserResponse} className="message-form" dir="ltr">
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    value={input}
                                    onChange={handleInputChange}
                                    placeholder="Kirjoita vastauksesi..."
                                    onPressEnter={handleUserResponse}
                                />
                                <Button className="sendButton" onClick={handleUserResponse} disabled={!input.trim() || isLoading}>
                                    Lähetä viesti
                                </Button>
                                {!isConversationEnded && (
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={openContactModal}
                                        disabled={isLoading}
                                        style={{ marginLeft: '10px', padding: '10px 15px 10px 15px', height: 'auto' }}
                                        dir="ltr"
                                    >
                                        Jätä yhteydenottopyyntö
                                    </Button>
                                )}
                            </form>
                        )}
                    </Spin>
                </div>
            </div>

            <Modal
                title="Jätä yhteydenottopyyntö"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Ota yhteyttä"
                cancelText="Peruuta"
            >
                <Form layout="vertical">
                    <Form.Item label="Nimi:">
                        <Input name="name" onChange={(e) => handleContactDataChange(e.target.value, 'name')} />
                    </Form.Item>
                    <Form.Item label="Puhelinnumero:">
                        <Input
                            type="tel"
                            name="phoneNumber"
                            pattern="[0-9]*"
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault(); // Estää ei-numeeriset merkit
                                }
                            }}
                            onChange={(e) => handleContactDataChange(e.target.value, 'phoneNumber')}
                        />
                    </Form.Item>

                    <Form.Item label="Sähköposti:" required>
                        <Input
                            type="email"
                            name="email"
                            onChange={(e) => handleContactDataChange(e.target.value, 'email')}
                            placeholder="esimerkki@domain.com"
                        />
                    </Form.Item>

                    <Form.Item label="Palvelukokonaisuus:">
                        <Select onChange={(value) => handleContactDataChange(value, 'servicePackage')}>
                            <Select.Option value="Rakennesuunnittelu">Rakennesuunnittelu</Select.Option>
                            <Select.Option value="Mekaniikkasuunnittelu">Mekaniikkasuunnittelu</Select.Option>
                            <Select.Option value="Sähkösuunnittelu">Sähkösuunnittelu</Select.Option>
                            <Select.Option value="Prosessisuunnittelu">Prosessisuunnittelu</Select.Option>
                            <Select.Option value="Lujuuslaskenta">Lujuuslaskenta</Select.Option>
                            <Select.Option value="Useiden palveluiden yhdistelmä">Useiden palveluiden yhdistelmä</Select.Option>
                            <Select.Option value="Muu, mikä?">Muu, mikä?</Select.Option>
                            <Select.Option value="En osaa sanoa">En osaa sanoa</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Kuvaile hanketta vapaasti:">
                        <Input.TextArea name="projectDescription" onChange={(e) => handleContactDataChange(e.target.value, 'projectDescription')} />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Hankkeen aloituspäivä:">
                                <DatePicker
                                    onChange={(date, dateString) => handleContactDataChange(dateString, 'startDate')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Hankkeen toivottu päättymispäivä:">
                                <DatePicker
                                    onChange={(date, dateString) => handleContactDataChange(dateString, 'endDate')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Onko projektissa mitään erityistä huomioitavaa, mikä menee standardit ja lainsäädännöt alle?">
                        <Input.TextArea name="standards" onChange={(e) => handleContactDataChange(e.target.value, 'standards')} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ChatWindow;
